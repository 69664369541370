import React from 'react';
import { useDispatch } from 'react-redux';
import { sendMessage } from '../Actions';

function ActionWidget({ title, data }) {
  const dispatch = useDispatch();

  const handleAction = (action) => {
    switch (action.type) {
      case 'chat':
        dispatch(sendMessage({
          type: "user",
          message: action.payload.message,
          thread_id: null,
          route: action.payload.route
        }));
        break;
      case 'open':
        window.open(action.payload.url, '_blank');
        break;
      case 'approve':
        // Implement approve action logic
        console.log('Approving task:', action.payload.task_id);
        break;
      default:
        console.warn('Unknown action type:', action.type);
    }
  };

  return (
    <div className="bg-neutral-800 shadow rounded-lg p-4">
      {/* <h2 className="text-lg font-semibold mb-2">{title}</h2> */}
      <div className="space-y-2">
        {data.actions.map((action, index) => (
          <button
            key={index}
            className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
            onClick={() => handleAction(action)}
          >
            {action.label}
          </button>
        ))}
      </div>
    </div>
  );
}

export default ActionWidget;