import React, { useState, useEffect, useRef } from 'react';
import { marked, setOptions, use } from 'marked';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { CameraAlt, AttachFile, Mic, PhotoOutlined } from '@mui/icons-material';
import TextareaAutosize from 'react-textarea-autosize';
import TypingText from './TypingText';
import '../assets/styles/AiResponseParser.css';
import { TextFieldsOutlined } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { Player } from '@lordicon/react';

const CAMERA = require('../assets/system-regular-32-videocam.json');
const PLAY = require('../assets/system-regular-26-play.json');

function Loader() {
    return <div className="loader"></div>;
}

function LoaderSmall() {
    return <div className="loader-small"></div>;
}

function typeEffect(element, delay) {
    let i = 0;
    let message = '...';
    let interval = setInterval(function () {
        if (i < message.length) {
            element.append(message.charAt(i));
            i++;
        } else {
            element.innerHTML = '';
            i = 0;
        }
    }, delay);
}

function removeEmptyMarkdown(text) {
    // Verwijder lege lijsten
    text = text.replace(/\n-\s*(\n|$)/g, '\n');
    // Verwijder lege koppen
    text = text.replace(/\n#+\s*(\n|$)/g, '\n');
    // Verwijder lege vetgedrukte tekst
    text = text.replace(/\*\*\s*\*\*/g, '');
    // Verwijder lege cursieve tekst
    text = text.replace(/\*\s*\*/g, '');
    // Verwijder lege doorgehaalde tekst
    text = text.replace(/~~\s*~~/g, '');
    // Verwijder lege codeblokken
    text = text.replace(/```\s*```/g, '');
    // Verwijder lege inline code
    text = text.replace(/`\s*`/g, '');
    // Verwijder lege regels
    text = text.replace(/\n\s*\n/g, '\n');
    return text;
}

function AIResponseParser({ aiResponse, onSubmit, onStop, onChange, onAction, loading, options, submitSimpleOption, typing = true, speed }) {
    const [inputs, setInputs] = useState({});
    const [response, setResponse] = useState([]);
    const [elements, setElements] = useState([]);
    const [isSubmitVisible, setIsSubmitVisible] = useState(false);
    const [selectedSingleChoice, setSelectedSingleChoice] = useState([]);
    const [selectedMultiChoices, setSelectedMultiChoices] = useState([]);
    const [selectedOptionIndex, setSelectedOptionIndex] = useState(null);
    const [containsButton, setContainsButton] = useState(false);
    const [containsElements, setContainsElements] = useState(false);
    const [submitClicked, setSubmitClicked] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [loadingButtonIndex, setLoadingButtonIndex] = useState(null);
    const [buttonElements, setButtonElements] = useState([]);
    const [currentElementIndex, setCurrentElementIndex] = useState(0);
    const [textAreaValue, setTextAreaValue] = useState('');
    const [doneAnimating, setDoneAnimating] = useState(false);
    const thinkingRef = useRef(null);
    const [textareaVisible, setTextareaVisible] = useState(true);
    const [stopSignalReceived, setStopSignalReceived] = useState(false);
    const thread_status = useSelector(state => state.main.status);
    const [currentThreadStatus, setCurrentThreadStatus] = useState("idle");
    const connectionStatus = useSelector(state => state.main.connectionStatus);
    const VideoPlayerRef = useRef(null);
    const PlayPlayerRef = useRef(null);

    const isStarted = (aiResponse && aiResponse.length > 0 && thread_status != "idle") || loading;

    useEffect(() => {
        setIsSubmitVisible(Object.keys(inputs).length > 0);
    }, [inputs]);


    useEffect(() => {
        setIsLoading(loading);
    }, [loading]);

    useEffect(() => {
        console.log('submitClicked: ', submitClicked);
        if (submitClicked) {
            handleSubmit();
            setSubmitClicked(false); // Reset for next click
        }
    }, [submitClicked]);

    // Call this function when the stop signal is received
    function handleStopSignal() {
        console.log('stop signal received');
        setStopSignalReceived(true);
    }

    useEffect(() => {
        return () => {
            if (stopSignalReceived) {

                //add timeout 
                setTimeout(() => {
                    onStop();
                }, 10000);
            }
        };
    }, [stopSignalReceived]);

    useEffect(() => {
        if (thinkingRef.current) {
            typeEffect(thinkingRef.current, 1000);
        }
    }, [thinkingRef]); // Voeg thinkingRef toe aan de afhankelijkheden van de hook


    const renderer = new marked.Renderer();

    renderer.link = function (href, title, text) {
        return `<a target="_blank" href="${href}" title="${title}">${text}</a>`;
    };

    marked.setOptions({ renderer });

    useEffect(() => {
        if (!response) return;
        console.log("UPDATING ELEMENTS")
        if (currentElementIndex < response?.length) {
            let currentElement = response[currentElementIndex];

            if (currentElement.type === TypingText) {
                // Als het huidige element een TypingText is, kloon het element en voeg de onComplete prop toe
                currentElement = React.cloneElement(currentElement, {
                    onComplete: () => {
                        if (currentElementIndex < response.length - 1) {
                            setCurrentElementIndex(prevIndex => prevIndex + 1);
                        } else {
                            console.log('done animating')
                            setDoneAnimating(true);
                        }
                    },
                });

                // Vervang het huidige element door het gekloonde element in de elements array
                setElements(prevElements => {
                    const newElements = [...prevElements];
                    newElements[currentElementIndex] = currentElement;
                    return newElements;
                });

            } else {
                // Als het huidige element geen TypingText is, wacht dan een bepaalde tijd (bijvoorbeeld de duur van de fade-in animatie)
                // voordat je doorgaat naar het volgende element
                let timeoutId;
                if (typing) {
                    timeoutId = setTimeout(() => {
                        setCurrentElementIndex(prevIndex => prevIndex + 1);
                    }, 1000); // Verander deze waarde naar de duur van je fade-in animatie
                } else {
                    setCurrentElementIndex(prevIndex => prevIndex + 1);
                }

                // currentElement = React.cloneElement(currentElement, {
                //     className: currentElement.props.className
                // });

                setElements(prevElements => {
                    const newElements = [...prevElements];
                    newElements[currentElementIndex] = currentElement;
                    return newElements;
                });

                if (typing)
                    // Cleanup function to clear the timeout if the component unmounts
                    return () => clearTimeout(timeoutId);

            }
        }

        // if we are at the end of the list
        if (currentElementIndex === response.length) {
            console.log('done animating')
            setDoneAnimating(true);
        }

    }, [currentElementIndex, response]);


    useEffect(() => {
        if (!containsElements && options && options.length > 0 && options.length < 4) {
            setButtonElements(options.map((buttonText, index) => (
                <button
                    key={`button-${index}`}
                    name={`button-${index}`}
                    onClick={(event) => {
                        event.preventDefault();
                        if (isLoading == true) return;

                        const currentIndex = index;
                        setLoadingButtonIndex(currentIndex);
                        setInputs((prevInputs) => ({
                            ...prevInputs,
                            ['button-' + index]: `button-${currentIndex}:${buttonText}`
                        }));
                        console.log('submitting..');
                        //setIsLoading(true);
                        setSubmitClicked(true);
                    }}
                >
                    {isLoading && (loadingButtonIndex == index) ?
                        <React.Fragment>{buttonText}<Loader /></React.Fragment> : buttonText}
                </button>
            )));

        } else {
            setOptions(options);
        }
    }, [options]);

    useEffect(() => {
        if (loadingButtonIndex !== null) {
            setIsLoading(true);
        }
        setButtonElements([]);
        setResponse(parseAndRenderElements(aiResponse));
    }, [isLoading, loadingButtonIndex]);

    useEffect(() => {
        // console.log('aiResponse: ', aiResponse);
        resetInterface();

        setResponse(parseAndRenderElements(aiResponse));
    }, [aiResponse]);

    useEffect(() => {
        if (thread_status == "starting" && currentThreadStatus == "active") {
            resetInterface();
            setResponse('');
        }

        setCurrentThreadStatus(thread_status);
    }, [thread_status]);

    useEffect(() => {
        console.log('selectedSingleChoice: ', selectedSingleChoice);
        const formattedData = Object.entries(inputs)
            .map(([key, value]) => `${key}: ${value}`)
            .join(', ');
        console.log('formattedData: ', formattedData);
        //setButtonElements([]);
        //setResponse(parseAndRenderElements(aiResponse))

        setElements(prevElements => {
            const newElements = [...prevElements];
            newElements?.forEach((element, index) => {
                if (element.props && element.key && element.key.indexOf('-container-') != -1) {
                    const choiceIndex = parseInt(element.key.split('-')[2]);
                    const newChildren = React.Children.map(element.props.children, child => {
                        if (child.type === 'button') {
                            if (child.props.name.startsWith('singlechoice-')) {
                                const optionIndex = parseInt(child.props.name.split('-')[2]);
                                const isSelected = selectedSingleChoice[choiceIndex] == optionIndex;
                                return React.cloneElement(child, {
                                    className: isSelected ? 'selected' : ''
                                });
                            } else if (child.props.name.startsWith('multichoice-')) {
                                const optionIndex = parseInt(child.props.name.split('-')[1]);
                                const isSelected = selectedMultiChoices.includes(optionIndex);
                                return React.cloneElement(child, {
                                    className: isSelected ? 'selected' : ''
                                });
                            }
                        }
                        return child;
                    });
                    newElements[index] = React.cloneElement(element, { children: newChildren });
                }
            });
            return newElements;
        });


        console.log("done updating elements");
    }, [selectedSingleChoice, selectedMultiChoices]);

    const resetInterface = () => {
        console.log('resetting')
        setInputs({});
        //setResponse({});
        setElements([]);
        setIsSubmitVisible(false);
        setSelectedSingleChoice([]);
        setButtonElements([]);
        setCurrentElementIndex(0);
        setSelectedMultiChoices([]);
        setLoadingButtonIndex(null);
        setDoneAnimating(false);
        setSelectedFile(null);
        setTextareaVisible(true);
        //setIsLoading(false);
        setContainsButton(false);
        setContainsElements(false);

        if (onChange)
            onChange(true, true); // Reset the chatbot interface

        setTextAreaValue('');
    }


    const parseAndRenderElements = (response) => {
        if (!response) {
            //setIsLoading(true);
            return;
        }
        const elements = [];
        const parts = response.split(/(<textarea:.*?>|<singlechoice:.*?>|<box:.*?>|<photoupload:.*?>|<link:.*?>|<multichoice:.*?>|<STOP>|<button:.*?>)/gs).filter(Boolean);
        let inputIndex = 0;
        let singleChoiceIndex = 0;
        let multiChoiceIndex = 0;
        let buttonIndex = 0;
        let tempButtonElements = [];

        parts.forEach((part, index) => {
            if (part.startsWith('<textarea:') || part.startsWith('<singlechoice:') || part.startsWith('<box:') || part.startsWith('<photoupload:') || part.startsWith('<link:') || part.startsWith('<STOP>') || part.startsWith('<multichoice:') || part.startsWith('<button:')) {
                const match = part.match(/<(textarea|singlechoice|photoupload|box|multichoice|button|link):(.*?)>|<STOP>/s);
                if (match) {
                    const [fullMatch, elementType, content] = match[1] ? match : [match[0], 'STOP', ''];

                    switch (elementType) {
                        case 'textarea':
                            // const [question, length] = content.split('|');
                            // setContainsElements(true);
                            // setTextareaVisible(false);
                            // elements.push(
                            //     <div key={fullMatch}>
                            //         {/* <input key={`input-${inputIndex}`}  type="text" name={`input-${inputIndex++}`}  placeholder={content} onChange={handleInputChange} /> */}
                            //         <TextareaAutosize
                            //             // style={{width: `${length}ch`}}
                            //             key={`input-${inputIndex}`}
                            //             name={`input-${inputIndex++}`}
                            //             placeholder={question}
                            //             onChange={handleInputChange}
                            //             onKeyDown={(event) => {
                            //                 if (event.key === 'Enter' && !event.shiftKey) {
                            //                     setInputs((prevInputs) => {
                            //                         const newInputs = { ...prevInputs };
                            //                         event.preventDefault();
                            //                         handleSubmit(event, newInputs);
                            //                         return newInputs;
                            //                     });
                            //                 }
                            //             }}
                            //         />
                            //     </div>
                            // );
                            break;


                        case 'link':
                            const [text, url] = content.split('|');
                            elements.push(
                                <div key={fullMatch}>
                                    <a href={url}>{text}</a>
                                </div>
                            );
                            break;


                        case 'box':
                            console.log("box!")
                            //const boxContent = content.replace(/^:/, '');
                            const adjustedMarkdown = content.replace(/\n/g, '<br />');
                            const markdownHtml = marked(adjustedMarkdown);
                            // const markdownHtml = marked(content);
                            elements.push(
                                <div className="ai-response-box" key={fullMatch} dangerouslySetInnerHTML={{ __html: markdownHtml }} />
                            );
                            break;

                        case 'STOP':
                            setContainsButton(true);
                            //handleStopSignal();
                            // tempButtonElements.push(<button
                            //     key={`stop`}
                            //     name={`stop`}
                            //     onClick={() => {
                            //         onStop();
                            //     }}
                            // >
                            //     Afsluiten
                            // </button>);
                            break;


                        // case 'photoupload':
                        //     elements.push(
                        //         <div key={fullMatch} className="ai-upload">
                        //             <input id="file-upload" key={`input-${inputIndex}`} type="file" name={`input-${inputIndex++}`} placeholder={content} onChange={handleUpload} />
                        //             <label htmlFor="file-upload"><PhotoOutlined />Kies bestand</label>
                        //         </div>
                        //     );
                        //     break;

                        case 'singlechoice':
                            const singleOptions = content.split('|');
                            elements.push(
                                <div className="ai-response-singlechoice" key={`singlechoice-container-${singleChoiceIndex}`}>
                                    {singleOptions.map((option, optionIndex) => {

                                        const currentSingleChoiceIndex = singleChoiceIndex;
                                        // Ensure selectedSingleChoice has a sub-array for the current question
                                        if (!selectedSingleChoice[currentSingleChoiceIndex]) {
                                            selectedSingleChoice[currentSingleChoiceIndex] = [];
                                        }
                                        return (
                                            <button
                                                key={`singlechoice-${currentSingleChoiceIndex}-${optionIndex}`}
                                                name={`singlechoice-${currentSingleChoiceIndex}-${optionIndex}`}
                                                type="button"
                                                className={selectedSingleChoice[currentSingleChoiceIndex] && selectedSingleChoice[currentSingleChoiceIndex][0] === optionIndex ? 'selected' : ''}
                                                onClick={() => {
                                                    if (isLoading == true) return;

                                                    setInputs((prevInputs) => {
                                                        // Remove previously selected option from inputs
                                                        const newInputs = { ...prevInputs };
                                                        Object.keys(newInputs).forEach((key) => {
                                                            if (key.startsWith(`singlechoice-${currentSingleChoiceIndex}-`)) {
                                                                delete newInputs[key];
                                                            }
                                                        });
                                                        // Add new selected option to inputs
                                                        newInputs[`singlechoice-${currentSingleChoiceIndex}-${optionIndex}`] = option;
                                                        return newInputs;
                                                    });
                                                    // Store the selected option for the current question
                                                    selectedSingleChoice[currentSingleChoiceIndex][0] = optionIndex;
                                                    setSelectedSingleChoice([...selectedSingleChoice]);
                                                }}
                                            >
                                                {option}
                                            </button>
                                        );
                                    })}
                                </div>
                            );
                            singleChoiceIndex++;
                            multiChoiceIndex++;
                            break;



                        case 'multichoice':
                            const multiOptions = content.split('|');
                            setContainsButton(true);
                            elements.push(
                                <div className="ai-response-multichoice" key={`multichoice-container-${multiChoiceIndex}`}>
                                    {multiOptions.map((option, index) => {
                                        const currentMultiChoiceIndex = index;
                                        return (
                                            <button
                                                key={`multichoice-${currentMultiChoiceIndex}`}
                                                name={`multichoice-${currentMultiChoiceIndex}`}
                                                type="button"
                                                className={selectedMultiChoices.includes(currentMultiChoiceIndex) ? 'selected' : ''} // Apply 'selected' class if this option is selected
                                                onClick={() => {
                                                    if (isLoading == true) return;

                                                    setInputs((prevInputs) => {
                                                        const newInputs = { ...prevInputs };
                                                        let deleted = false;
                                                        Object.keys(newInputs).forEach((key) => {
                                                            if (key.startsWith(`multichoice-${currentMultiChoiceIndex}`)) {
                                                                delete newInputs[key];
                                                                deleted = true;
                                                            }
                                                        });
                                                        // if (selectedMultiChoices.includes(currentMultiChoiceIndex)) {
                                                        //     // If this option is already selected, remove it from inputs
                                                        //     delete newInputs[`multichoice-${currentMultiChoiceIndex}`];
                                                        // } else {
                                                        // If this option is not selected, add it to inputs
                                                        if (!deleted)
                                                            newInputs[`multichoice-${currentMultiChoiceIndex}`] = option;
                                                        //}
                                                        return newInputs;
                                                    });
                                                    setSelectedMultiChoices((prevChoices) => { // Update selected options
                                                        const newChoices = [...prevChoices];
                                                        if (prevChoices.includes(currentMultiChoiceIndex)) {
                                                            return prevChoices.filter(choiceIndex => choiceIndex !== currentMultiChoiceIndex);
                                                        } else {
                                                            return [...prevChoices, currentMultiChoiceIndex];
                                                        }
                                                    });
                                                }}
                                            >
                                                {option}
                                            </button>
                                        );
                                    })}
                                </div>
                            );
                            break;

                        case 'button':
                            //const [buttonText, action] = content.split('|');
                            // const currentButtonIndex = buttonIndex;
                            // setContainsButton(true);
                            // // setButtonElements(prevButtonElements => [
                            // //     ...prevButtonElements,
                            // //options.push(
                            // tempButtonElements.push(
                            //     <button
                            //         key={`button-${currentButtonIndex}`}
                            //         name={`button-${currentButtonIndex}`}
                            //         onClick={(event) => {
                            //             event.preventDefault();
                            //             if (isLoading == true) return;

                            //             const currentIndex = currentButtonIndex;
                            //             setLoadingButtonIndex(currentIndex);
                            //             setInputs((prevInputs) => ({
                            //                 ...prevInputs,
                            //                 [content]: `button-${currentIndex}:${content}`
                            //             }));
                            //             console.log('submitting..');
                            //             //setIsLoading(true);
                            //             setSubmitClicked(true);
                            //         }}
                            //     >
                            //         {isLoading && (loadingButtonIndex == currentButtonIndex) ?
                            //             <React.Fragment>{content}<Loader /></React.Fragment> :
                            //             content}
                            //     </button>
                            //     //    ]);
                            // );
                            // buttonIndex++;
                            break;

                        default:
                            break;
                    }
                }
            } else {

                // const parser = new DOMParser();
                // const doc = parser.parseFromString(markdownHtml, "text/html");
                // const plainText = doc.body.textContent || "";


                // const cleaned = removeEmptyMarkdown(part);
                // const adjustedMarkdown = cleaned.replace(/\n/g, '\n\n');
                // const markdownHtml = marked(adjustedMarkdown);
                // // const markdownHtml = marked(cleaned);

                // if (markdownHtml != "") {
                //     elements.push(
                //         //<div key={`markdown-${index}`} dangerouslySetInnerHTML={{ __html: markdownHtml }} />
                //         <TypingText typing={typing} speed={speed} key={`markdown-${index}`} html={markdownHtml} />

                //         //   onComplete={() => { 
                //         //         if (currentElementIndex < elements.length - 1) {
                //         //             setCurrentElementIndex(currentElementIndex + 1);
                //         //         }
                //         //   }} />
                //     );
                // }
            }
        });

        setButtonElements(tempButtonElements);
        return elements;
    };

    const textAreaRef = useRef(null);

    const handleInputChange = (e) => {
        if (isLoading == true) return;
        const { name, value } = e.target;
        setInputs((prevInputs) => ({
            ...prevInputs,
            [name]: value,
        }));
    };

    const handleTextareaChange = (e) => {
        if (isLoading == true) return;
        setTextAreaValue(e.target.value);

        if (textAreaRef?.current?.scrollHeight > textAreaRef?.current?.offsetHeight) {
            textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
        }

        setInputs((prevInputs) => ({
            ...prevInputs,
            ['textarea']: e.target.value,
        }));
    };

    const handleUpload = (e) => {
        if (isLoading == true) return;

        // in following format: "data:image/jpeg;base64,{base64_image}"
        const file = e.target.files[0];
        const reader = new FileReader();


        console.log('selectedFile: ', file);
        reader.onload = function () {
            setInputs((prevInputs) => ({
                ...prevInputs,
                [e.target.files[0].name]: reader.result,
            }));

            setSelectedFile({
                file: file,
                dataUrl: reader.result
            });
        };
        reader.readAsDataURL(file);
    }

    const handleSubmit = (e, tempInputs) => {
        if (e != null) e.preventDefault();
        if (isLoading == true) return;
        console.log('submitting');
        setIsLoading(true);

        if (!tempInputs) {
            tempInputs = inputs;
        }

        let formattedData = Object.entries(tempInputs)
            .map(([key, value]) => `${key}: ${value}`)
            .join(', ');

        if (formattedData === '') {
            formattedData = "Geen invoer, probeer verder te gaan zonder invoer."
        }

        onSubmit(formattedData);
        setTextAreaValue('');
    };


    // Generate an array of 5 random numbers between 50 and 100
    const randomWidths = Array.from({ length: 4 }, () => Math.floor(Math.random() * 50) + 10);


    return (
        <div className="ai-response-container">

            {(!isStarted && connectionStatus == "connected" && thread_status == "idle") && (
                <div className='user-input'>
                    <TextareaAutosize
                        type="text"
                        ref={textAreaRef}
                        value={textAreaValue}
                        onChange={handleTextareaChange}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter' && !event.shiftKey) {
                                event.preventDefault();
                                handleSubmit(event);
                            }
                        }}
                        placeholder={"Je bericht of reactie hier"}
                    />
                    <div className="icon-wrapper">
                        <div onClick={() => { onAction('camera'); }} onMouseEnter={() => { VideoPlayerRef.current?.playFromBeginning() }}>
                            <Player
                                ref={VideoPlayerRef}
                                size={28}
                                icon={CAMERA}
                                colorize='#D94773'
                            />
                        </div>
                        <div onClick={() => { handleSubmit(); }} onMouseEnter={() => { PlayPlayerRef.current?.playFromBeginning() }}>
                            <Player
                                ref={PlayPlayerRef}
                                size={28}
                                icon={PLAY}
                                colorize='#D94773'
                            />
                        </div>
                    </div>
                </div>

            )}

            {isStarted && (
                <form name="ai-form" key="ai-form" className="ai-form" onSubmit={handleSubmit}>
                    {/* <div className={`ai-response ${!isLoading ? 'visible' : ''}`}>
                        {!isLoading && (
                            elements?.slice(0, currentElementIndex + 1).map((element, index) => (
                                (element == null) ? null :
                                    <div key={index} className={(element.type !== TypingText && typing) ? "fade-in" : ""}>
                                        {element}
                                    </div>
                            ))
                        )}
                    </div> */}

                    <div className='ai-options-auto'>
                        {!isLoading && (
                            elements?.slice(0, currentElementIndex + 1).map((element, index) => (
                                (element == null) ? null :
                                    <div key={index} className={(element.type !== TypingText && typing) ? "fade-in" : ""}>
                                        {element}
                                    </div>
                            ))
                        )}
                    </div>

                    {!isLoading && doneAnimating && containsButton && isSubmitVisible && (
                        (isSubmitVisible && buttonElements?.length == 0) && (
                            <button type="submit">{isLoading ? <span><Loader />verstuur</span> : "verstuur"}</button>
                        )
                    )}

                    {(!isLoading && !containsButton && containsElements && isSubmitVisible) || (doneAnimating && buttonElements?.length > 0 && buttonElements) && (
                        <div className='ai-options-auto'>
                            {/* {textareaVisible ?
                                <div className='ai-input'>
                                    <TextareaAutosize
                                        type="text"
                                        ref={textAreaRef}
                                        value={textAreaValue}
                                        onChange={handleTextareaChange}
                                        onKeyDown={(event) => {
                                            if (event.key === 'Enter' && !event.shiftKey) {
                                                event.preventDefault();
                                                handleSubmit(event);
                                            }
                                        }}
                                        placeholder={"Typ hier je reactie"}
                                    />

                                </div>
                                : (containsButton &&
                                    <button type="button" className="open-input" onClick={(e) => { e.stopPropagation(); setTextareaVisible(true); }}><TextFieldsOutlined /></button>)
                            } */}
                            {/* {!containsButton && containsElements && isSubmitVisible && (
                                <button type="submit">{isLoading ? <React.Fragment>bezig.. <Loader /></React.Fragment> : "Verstuur"}</button>
                            )} */}

                            {!isLoading && doneAnimating && buttonElements?.length > 0 && buttonElements}

                            {/* {!isLoading && doneAnimating && textareaVisible && containsButton && (
                                <TextareaAutosize
                                    type="text"
                                    ref={textAreaRef}
                                    value={textAreaValue}
                                    onChange={handleTextareaChange}
                                    onKeyDown={(event) => {
                                        if (event.key === 'Enter' && !event.shiftKey) {
                                            event.preventDefault();
                                            handleSubmit(event);
                                        }
                                    }}
                                    placeholder={"Of typ hier je reactie"}
                                />
                            )} */}

                            {/* {!isLoading && doneAnimating && (
                                <button onClick={onStop}>Afsluiten</button>
                            )} */}


                            {/* {(isSubmitVisible && buttonElements?.length == 0) && (
                                <button type="submit">{isLoading ? <span><Loader />verstuur</span> : "verstuur"}</button>
                            )} */}
                        </div>
                    )}

                    {/* {!isLoading && textareaVisible && buttonElements?.length == 0 && ( */}
                    {/* {!isLoading && !stopSignalReceived && textareaVisible && (
                        <div className='ai-options-auto'>
                            <TextareaAutosize
                                type="text"
                                ref={textAreaRef}
                                value={textAreaValue}
                                onChange={handleTextareaChange}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter' && !event.shiftKey) {
                                        event.preventDefault();
                                        handleSubmit(event);
                                    }
                                }}
                                placeholder={"Of typ hier je reactie"}
                            />
                        </div>
                    )} */}





                    <div className='ai-information'>
                        {selectedFile && (
                            <div>
                                <Tooltip title={selectedFile.file.name} arrow>
                                    <img src={selectedFile.dataUrl} alt="Geüploade afbeelding" />
                                </Tooltip>
                                <IconButton onClick={() => setSelectedFile(null)}>
                                    <CloseIcon />
                                </IconButton>
                            </div>
                        )}

                    </div>
                </form>
            )}
        </div>
    );
}

export default AIResponseParser;