import React from "react";
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './components/Store';
import { BrowserRouter } from 'react-router-dom';

import { MsalProvider } from "@azure/msal-react";
import { Configuration, PublicClientApplication } from "@azure/msal-browser";

import App from "./App";
import './index.css';

// MSAL configuration
const configuration = {
    auth: {
        clientId: "7ae91f63-d4bb-4bff-980f-bd0c0fead9e8",
        //authority: 'https://login.microsoftonline.com/00b411b8-5cbf-465c-a350-98dab7f51283', //single tenant
        authority: 'https://login.microsoftonline.com/common', //multi tenant
        redirectUri: window.location.origin + "/login"
    },
    cache: {
        cacheLocation: "localStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // If you set this to true, then state will be stored in a cookie and cache will be stored in memory with either sessionStorage or localStorage
    }
};

const pca = new PublicClientApplication(configuration);

// Component
const AppProvider = () => (
    <MsalProvider instance={pca}>
        <Provider store={store}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>
    </MsalProvider>
);

const domNode = document.getElementById('root');
const root = createRoot(domNode);
root.render(<AppProvider />);

// Check if service workers are supported
if ('serviceWorker' in navigator) {
    window.addEventListener('load', function () {
        // Register the service worker
        navigator.serviceWorker.register('/serviceWorker.js').then(function (registration) {
            // Registration was successful
            console.log('ServiceWorker registration successful with scope: ', registration.scope);

            // Listen for messages from the Service Worker
            navigator.serviceWorker.addEventListener('message', (event) => {
                if (event.data && event.data.type === 'NOTIFICATION_RECEIVED') {
                    // Dispatch a Redux action
                    store.dispatch({ type: 'NOTIFICATION_RECEIVED', payload: event.data.payload });
                }
            });
        }, function (err) {
            // registration failed :(
            console.log('ServiceWorker registration failed: ', err);
        });
    });
}