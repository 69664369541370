import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { sendMessage } from '../Actions';
import * as Icons from '@mui/icons-material';

const AppWidget = ({ title, data }) => {
    const dispatch = useDispatch();
    const [hoveredActionIndex, setHoveredActionIndex] = useState(null);
    const [isTileHovered, setIsTileHovered] = useState(false);
    const [showNotification, setShowNotification] = useState(false);

    const handleAction = (action) => {
        switch (action.action) {
            case 'chat':
                dispatch(
                    sendMessage({
                        type: 'user',
                        message: action.payload.message,
                        thread_id: null,
                    })
                );
                break;
            case 'open':
                window.open(action.payload.url, '_blank');
                break;
            default:
                console.warn('Unknown action type:', action.type);
        }
    };

    const getIconComponent = (iconName) => {
        const IconComponent = Icons[iconName];
        return IconComponent ? <IconComponent /> : null;
    };

    const latestNotification =
        data.notifications && data.notifications.length > 0
            ? data.notifications[0]
            : null;

    return (
        <div
            className="app-widget-tile"
            onClick={() => window.open(data.url, '_blank')}
            onMouseEnter={() => setIsTileHovered(true)}
            onMouseLeave={() => {
                setIsTileHovered(false);
                setHoveredActionIndex(null);
            }}
        >
            {/* Notification bubble */}
            {data.notifications && data.notifications.length > 0 && (
                <div
                    className="notification-bubble"
                    onClick={(e) => {
                        e.stopPropagation(); // prevent triggering tile click
                        setShowNotification(!showNotification);
                    }}
                >
                    {data.notifications.length}
                </div>
            )}

            {/* Logo */}
            {!showNotification && (
                <div
                    className={`logo-container ${isTileHovered ? 'logo-container-hovered' : ''
                        }`}
                >
                    <img
                        src={data.icon_url}
                        alt={title}
                        className={`app-logo ${isTileHovered ? 'app-logo-hovered' : ''}`}
                    />
                </div>
            )}

            {/* Latest Notification */}
            {showNotification && latestNotification && (
                <div className="notification-content">
                    <strong>{latestNotification.title}</strong>:{' '}
                    {latestNotification.description}
                </div>
            )}

            {/* Actions */}
            {isTileHovered && !showNotification && (
                <div className="actions-container">
                    {data.actions.map((action, index) => (
                        <div
                            key={index}
                            className="action-button-wrapper"
                            onMouseEnter={() => setHoveredActionIndex(index)}
                            onMouseLeave={() => setHoveredActionIndex(null)}
                        >
                            <button
                                className="action-button"
                                onClick={(e) => {
                                    e.stopPropagation(); // prevent triggering tile click
                                    handleAction(action);
                                }}
                            >
                                {getIconComponent(action.icon)}
                            </button>
                            {hoveredActionIndex === index && (
                                <div className="tooltip">{action.label}</div>
                            )}
                        </div>
                    ))}
                </div>
            )}

            {isTileHovered && !showNotification && !data.actions?.length && (
                 <div className="app-title">{title}</div>
            )}
        </div>
    );
};

export default AppWidget;
