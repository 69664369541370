import React, { useState } from 'react';
import { CalendarDays } from 'lucide-react';

const InfoWidget = ({ title, data }) => {
    // Initialize state with the items passed in props
    const [items, setItems] = useState(data.items);

    // Function to handle swapping items
    const handleItemClick = (index) => {
        const newItems = [...items];
        // Swap the main item (index 0) with the clicked item (index)
        [newItems[0], newItems[index]] = [newItems[index], newItems[0]];
        setItems(newItems);
    };

    const renderContent = () => {
        switch (data.type) {
            case 'tasks':
                return null;

            case 'news':
                const mainItem = items[0];
                const otherItems = items.slice(1, 3); // Get the next two items

                return (
                    <React.Fragment>
                        {/* Main Item */}
                        <div className="main-item">
                            <h2 className="main-title">{mainItem.title}</h2>
                            <div className="main-content">
                                {mainItem.imageUrl && (
                                    <img
                                        src={mainItem.imageUrl || '/api/placeholder/80/80'}
                                        alt={mainItem.title}
                                        className="main-image"
                                    />
                                )}
                                <div className="content-and-actions">
                                    <p className="summary">{mainItem.summary}</p>
                                    <div className="meta-and-actions">
                                        <div className="meta">
                                            <div className="author-and-date">
                                                {mainItem.author && (
                                                    <span className="author">{mainItem.author}</span>
                                                )}
                                                {mainItem.date && (
                                                    <span className="date">
                                                        <CalendarDays size={14} className="calendar-icon" />
                                                        {mainItem.date}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="actions">
                                            <a
                                                href={mainItem.link}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <button className="read-more-button">Lees meer</button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Other Items */}
                        <div className="other-items">
                            {otherItems.map((item, index) => (
                                <div
                                    key={index + 1} // Adjust index since main item is at index 0
                                    className="other-item"
                                    onClick={() => handleItemClick(index + 1)}
                                >
                                    <div className="other-item-link">
                                        {item.imageUrl && (
                                            <img
                                                src={item.imageUrl || '/api/placeholder/80/80'}
                                                alt={item.title}
                                                className="other-item-image"
                                            />
                                        )}
                                        <div className="other-item-title">{item.title}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </React.Fragment>
                );

            default:
                return <div dangerouslySetInnerHTML={{ __html: data }} />;
        }
    };

    return (
        <div className="info-widget">
            {/* <h2 className="widget-title">{title}</h2> */}
            {renderContent()}
        </div>
    );
};

export default InfoWidget;
